// chatModels.js

export const AIProvider = {
    OPENAI: 'OpenAI',
    OPENAI_REASONING: 'OpenAI Reasoning',
    IMAGE_GENERATION: 'Image Generation',
    ANTHROPIC: 'Anthropic',
    GOOGLE: 'Google',
    AUTOSELECTION: 'Auto Selection',
    PERPLEXITY: 'Perplexity',
};

export const ChatModel = {
    AUTO_SELECTION: {
        name: "Auto Selection",
        color: "#007AFF", // Purple color for GPT models
        provider: AIProvider.AUTOSELECTION,
        subtitle: "Picks the best model (beta)",
        symbol: "brain", // Optional if you want to use symbols
        modelString: "auto", // The model string for API calls
        maxTokens: 4048,
        isPremium: false,
    },
    PERPLEXITY: {
        name: "Perplexity",
        color: "#434343", // Purple color for GPT models
        provider: AIProvider.PERPLEXITY,
        subtitle: "Browses the web",
        symbol: "globe", // Optional if you want to use symbols
        modelString: "llama-3.1-sonar-large-128k-online", // The model string for API calls
        maxTokens: 4048,
        isPremium: true,
    },
    GPT4O_MINI: {
        name: "GPT 4o mini",
        color: "#8e44ad", // Purple color for GPT models
        provider: AIProvider.OPENAI,
        subtitle: "Fastest",
        symbol: "bolt.fill", // Optional if you want to use symbols
        modelString: "gpt-4o-mini", // The model string for API calls
        maxTokens: 16383,
        isPremium: false
    },
    GPT4O: {
        name: "GPT 4o",
        color: "#8e44ad",
        provider: AIProvider.OPENAI,
        subtitle: "Flagship",
        symbol: "smallcircle.filled.circle",
        modelString: "gpt-4o", // The model string for API calls
        maxTokens: 4095,
        isPremium: false
    },
    GPT4_TURBO: {
        name: "GPT 4 Turbo",
        color: "#1abc9c", // Teal color for Turbo models
        provider: AIProvider.OPENAI,
        subtitle: "Intelligent",
        symbol: "medal.fill",
        modelString: "gpt-4-turbo", // The model string for API calls
        maxTokens: 16383,
        isPremium: true
    },
    GPT_O1_MINI: {
        name: "o1-mini",
        color: "#1abc9c",
        provider: AIProvider.OPENAI_REASONING,
        subtitle: "Fast Reasoning (beta)",
        symbol: "brain",
        modelString: "o1-mini", // The model string for API calls
        maxTokens: 4096,
        isPremium: true
    },
    GPT_O1_PREVIEW: {
        name: "o1-preview",
        color: "#1abc9c",
        provider: AIProvider.OPENAI_REASONING,
        subtitle: "Advanced Reasoning (beta)",
        symbol: "scale.3d",
        modelString: "o1-preview", // The model string for API calls
        maxTokens: 4096,
        isPremium: true
    },
    DALLE3: {
        name: "DALL-E 3",
        color: "#8e44ad",
        provider: AIProvider.IMAGE_GENERATION,
        subtitle: "Image generation",
        symbol: "scale.3d",
        modelString: "dall-e-3", // The model string for API calls
        maxTokens: 4096,
        isPremium: true
    },
    CLAUDE3_HAIKU: {
        name: "Claude 3 Haiku",
        color: "#2ecc71", // Mint color for Claude models
        provider: AIProvider.ANTHROPIC,
        subtitle: "Fastest",
        symbol: "bolt.fill",
        modelString: "claude-3-haiku-20240307", // The model string for API calls
        maxTokens: 4096,
        isPremium: false
    },
    CLAUDE3_SONNET: {
        name: "Claude 3 Sonnet",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Intelligent",
        symbol: "scale.3d",
        modelString: "claude-3-sonnet-20240229", // The model string for API calls
        maxTokens: 4096,
        isPremium: true
    },
    CLAUDE3_OPUS: {
        name: "Claude 3 Opus",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Powerful",
        symbol: "pencil.and.outline",
        modelString: "claude-3-opus-20240229", // The model string for API calls
        maxTokens: 4096,
        isPremium: true
    },
    CLAUDE3_5_SONNET: {
        name: "Claude 3.5 Sonnet",
        color: "#2ecc71",
        provider: AIProvider.ANTHROPIC,
        subtitle: "Flagship",
        symbol: "medal.fill",
        modelString: "claude-3-5-sonnet-20241022", // The model string for API calls
        maxTokens: 8192,
        isPremium: true
    },
    GEMINI1_5_FLASH: {
        name: "Gemini 1.5 Flash",
        color: "#e91e63", // Pink color for Gemini models
        provider: AIProvider.GOOGLE,
        subtitle: "Fastest",
        symbol: "bolt.fill",
        modelString: "gemini-1.5-flash", // The model string for API calls
        isPremium: false
    },
    GEMINI1_5_PRO: {
        name: "Gemini 1.5 Pro",
        color: "#e91e63",
        provider: AIProvider.GOOGLE,
        subtitle: "Flagship",
        symbol: "medal.fill",
        modelString: "gemini-1.5-pro", // The model string for API calls
        isPremium: true
    },
    GEMINI1_0_PRO: {
        name: "Gemini 1.0 Pro",
        color: "#e91e63",
        provider: AIProvider.GOOGLE,
        subtitle: "Simple",
        symbol: "hourglass.bottomhalf.filled",
        modelString: "gemini-1.0-pro", // The model string for API calls
        isPremium: true
    },
};

// If you want to access models by name
export const getChatModelByName = (name) => {
    return Object.values(ChatModel).find(model => model.name === name);
};
export const getChatModelByModelString = (modelString) => {
    return Object.values(ChatModel).find(model => model.modelString === modelString);
};

export const getEndpoint = (model, hasSubscription) => {
    if (isAutoSelection(model)) {
        return hasSubscription
            ? 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/intelligent-model-selection-paid'
            : 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/intelligent-model-selection-free';
    } else if (isPerplexity(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/perplexity';
    } else if (isOpenAI(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai';
    } else if (isOpenAIReasoning(model)) {
        //return 'http://192.168.1.158:8080/api/openai-reasoning2';
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai-reasoning2';
    } else if (isAnthropic(model)) {
        //return 'http://192.168.1.158:8080/api/anthropic_web';
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/anthropic_web';
    } else if (isImageGeneration(model)) {
        return 'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/openai-image-gen3';
    }
};

export const isAutoSelection = (model) => {
    return model.provider === AIProvider.AUTOSELECTION;
};
export const isPerplexity = (model) => {
    return model.provider === AIProvider.PERPLEXITY;
};
export const isOpenAI = (model) => {
    return model.provider === AIProvider.OPENAI;
};
export const isOpenAIReasoning = (model) => {
    return model.provider === AIProvider.OPENAI_REASONING;
};
export const isAnthropic = (model) => {
    return model.provider === AIProvider.ANTHROPIC;
};
export const isGoogle = (model) => {
    return model.provider === AIProvider.GOOGLE;
};
export const isImageGeneration = (model) => {  
    return model.provider === AIProvider.IMAGE_GENERATION;
};

export const getMaxTokens = (modelString) => {
    const model = getChatModelByModelString(modelString);
    return model ? model.maxTokens : 4000; // Default to 4000 if model not found
};