import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, setDoc, addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseConfig, firestore, auth, storage } from './firebase';
import uuid from 'react-native-uuid';
import { Send, Image as ImageIcon, FileText as FileIcon, X, Plus } from 'lucide-react';
import { getChatModelByModelString, isGoogle, getMaxTokens, getEndpoint, isOpenAIReasoning, isAnthropic, isImageGeneration } from './chatModels';
import { initializeApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const MessageInput = ({ selectedChat, selectedModel, selectedModelName, onMessageSent, messages = [], updateChatTitle, hasSubscription, hasBasicSubscription }) => {
  const [input, setInput] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [showFileMenu, setShowFileMenu] = useState(false);
  const lastUpdateRef = useRef(0);
  const updateIntervalRef = useRef(100);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const pdfInputRef = useRef(null);
  const fileMenuRef = useRef(null);
  const [imageBase64, setImageBase64] = useState(null);
  const firebaseApp = initializeApp(firebaseConfig);
  const vertexAI = getVertexAI(firebaseApp);
  const navigate = useNavigate();

  const isSonnet = selectedModel === 'claude-3-5-sonnet-20241022';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (fileMenuRef.current && !fileMenuRef.current.contains(event.target)) {
        setShowFileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const convertToJPEG = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
            resolve(convertedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const fileToGenerativePart = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          inlineData: {
            data: reader.result.split(',')[1],
            mimeType: file.type
          },
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const clearFileState = () => {
    setSelectedImage(null);
    setSelectedPdf(null);
    setImagePreviewUrl(null);
    setPdfPreviewUrl(null);
    setImageBase64(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
    if (pdfInputRef.current) pdfInputRef.current.value = '';
  };

  const handleSendMessage = useCallback(async () => {
    if (!input.trim() && !selectedImage && !selectedPdf) return;
    if (!auth.currentUser?.uid) {
      navigate('/welcome');
      return;
    }

    let fileUrl = '';
    let imageUrl = '';
    let imagePart = null;

    const userMessage = {
      id: uuid.v4(),
      text: input,
      role: 'user',
      streaming: false,
      pending: true,
      localImage: true
    };
    
    if (imagePreviewUrl) {
      userMessage.imageUrl = imagePreviewUrl;
      userMessage.localImage = true;
    } else if (pdfPreviewUrl) {
      userMessage.pdfUrl = pdfPreviewUrl;
      userMessage.localPdf = true;
    }

    let chatId = selectedChat?.id;

    try {
      if (!chatId) {
        const newChatRef = doc(collection(firestore, 'chats'));
        chatId = newChatRef.id;

        const modelName = selectedModelName || 'GPT 4o mini';
        await setDoc(newChatRef, {
          id: chatId,
          owner: auth.currentUser.uid,
          model: modelName,
          topic: 'Untitled Chat',
          lastMessageSent: serverTimestamp(),
        });
      }

      onMessageSent(userMessage, chatId);
      setInput('');
      clearFileState();

      if (selectedImage || selectedPdf) {
        const file = selectedImage || selectedPdf;
        const fileType = selectedImage ? 'image' : 'pdf';
        const fileRef = ref(storage, `chat_${fileType}s/${auth.currentUser.uid}/${Date.now()}_${fileType}.${fileType === 'image' ? 'jpg' : 'pdf'}`);
        
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
        
        if (fileType === 'image') {
          userMessage.imageUrl = fileUrl;
          imagePart = await fileToGenerativePart(file);
        } else {
          userMessage.pdfUrl = fileUrl;
          userMessage.pdfName = file.name;
        }
        
        onMessageSent(userMessage, chatId);
      }

      await addMessageToFirestore(chatId, userMessage);

      userMessage.pending = false;
      onMessageSent(userMessage, chatId);

      const modelDetails = getChatModelByModelString(selectedModel);
      const assistantMessageId = uuid.v4();
      let accumulatedResponse = '';
      const conversationHistory = [...messages, userMessage];

      const maxTokens = getMaxTokens(selectedModel);
      const updateMessage = (text) => {
        const now = Date.now();
        if (now - lastUpdateRef.current >= updateIntervalRef.current) {
          if (!assistantMessage.text) {
            assistantMessage.text = text.trim();
            onMessageSent(assistantMessage, chatId);
          } else {
            assistantMessage.text = text.trim();
            onMessageSent(assistantMessage, chatId);
          }
          lastUpdateRef.current = now;
        }
      };

      const assistantMessage = {
        id: assistantMessageId,
        text: '',
        role: 'assistant',
        streaming: true,
      };

      if (isGoogle(modelDetails)) {
        const model = getGenerativeModel(vertexAI, { model: selectedModel });
        const chat = model.startChat({
          history: conversationHistory.map(msg => ({
            role: msg.role === 'user' ? 'user' : 'model',
            parts: [{ text: msg.text }],
          })),
          generationConfig: {
            maxOutputTokens: maxTokens,
          },
        });

        const parts = [{ text: input }];
        if (imagePart) {
          parts.push(imagePart);
        }

        const result = await chat.sendMessageStream(parts);

        for await (const chunk of result.stream) {
          const chunkText = chunk.text();
          accumulatedResponse += chunkText;
          updateMessage(accumulatedResponse);
        }
      } 
      // else if (isOpenAIReasoning(modelDetails)) {
      //   const requestBody = {
      //     messages: conversationHistory,
      //     model: selectedModel,
      //   };

      //   const authToken = await auth.currentUser.getIdToken();
      //   const endpoint = getEndpoint(modelDetails, hasSubscription);
      //   const response = await fetch(endpoint, {
      //     method: 'POST',
      //     headers: {
      //       'Authorization': `Bearer ${authToken}`,
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(requestBody),
      //   });

      //   const data = await response.json();
      //   if (data.content) {
      //     accumulatedResponse = data.content;
      //     updateMessage(accumulatedResponse);
      //   } else {
      //     throw new Error('Unexpected response format');
      //   }
      // }
       else if (isImageGeneration(modelDetails)) {
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const requestBody = {
          messages: conversationHistory,
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const imageResponse = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (imageResponse.ok) {
          const imageData = await imageResponse.json();
          imageUrl = imageData.image;
          
          assistantMessage.streaming = false;
          assistantMessage.text = imageUrl;

          onMessageSent(assistantMessage, chatId);
          await addMessageToFirestore(chatId, assistantMessage);
          await generateAndUpdateTopic(imageUrl, chatId);
        } else {
          console.error('Image generation failed');
        }
      } else {
        const requestBody = {
          messages: conversationHistory,
          model: selectedModel,
          imageBase64: imageBase64,
          max_tokens: maxTokens
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          for (const line of lines) {
            if (line.startsWith('data:')) {
              const jsonString = line.slice(5).trim();
              if (jsonString === '[DONE]') {
                done = true;
                break;
              }
              try {
                const { content } = JSON.parse(jsonString);
                accumulatedResponse += content;
                updateMessage(accumulatedResponse);
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }
      }

      if (!isImageGeneration(modelDetails)) {
        assistantMessage.streaming = false;
        assistantMessage.text = accumulatedResponse.trim();
        onMessageSent(assistantMessage, chatId);
        await addMessageToFirestore(chatId, assistantMessage);
        await generateAndUpdateTopic(accumulatedResponse.trim(), chatId);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [input, selectedImage, selectedPdf, imageBase64, selectedChat, selectedModel, selectedModelName, onMessageSent, messages]);

  const handleFileSelect = async (event, fileType) => {
    const file = event.target.files[0];
    if (!file) return;
    setShowFileMenu(false);

    if (fileType === 'image') {
      const jpegFile = await convertToJPEG(file);
      setSelectedImage(jpegFile);
      setSelectedPdf(null);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setPdfPreviewUrl(null);
        const base64String = reader.result.split(',')[1];
        setImageBase64(base64String);
      };
      reader.readAsDataURL(jpegFile);
    } else if (fileType === 'pdf' && isSonnet) {
      setSelectedPdf(file);
      setSelectedImage(null);
      setImageBase64(null);
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setPdfPreviewUrl(reader.result);
        setImagePreviewUrl(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    clearFileState();
  };

  const triggerFileSelect = (fileType) => {
    if (fileType === 'image') {
      fileInputRef.current.click();
    } else if (fileType === 'pdf' && isSonnet) {
      pdfInputRef.current.click();
    }
    setShowFileMenu(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const addMessageToFirestore = async (chatId, message) => {
    const messagesRef = collection(firestore, 'chats', chatId, 'messages');
    const messageData = {
      id: message.id,
      text: message.text,
      role: message.role,
      createdAt: serverTimestamp(),
    };

    if (message.role === 'user') {
      if (message.imageUrl) messageData.imageUrl = message.imageUrl;
      if (message.pdfUrl) {
        messageData.pdfUrl = message.pdfUrl;
        messageData.pdfName = message.pdfName;
      }
      if (message.imageBase64) messageData.imageBase64 = message.imageBase64;
    }

    await addDoc(messagesRef, messageData);

    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      lastMessageSent: serverTimestamp(),
    });
  };

  const generateAndUpdateTopic = async (responseText, chatId) => {
    const lastUserMessage = messages[messages.length - 2]?.text || '';
    const lastAssistantMessage = responseText;

    const topicPrompt = `
      Based on the following conversation, generate a concise topic of 4-5 words maximum and 30 letters or less:
      User: ${lastUserMessage}
      AI: ${lastAssistantMessage}
      Topic:
    `;

    const authToken = await auth.currentUser.getIdToken();
    const topicResponse = await fetch('https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/generate-topic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({ prompt: topicPrompt }),
    });

    const result = await topicResponse.json();
    const generatedTopic = result.topic;

    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      topic: generatedTopic,
    });

    updateChatTitle(generatedTopic);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 300)}px`;
    }
  }, [input]);

  const isInputEmpty = input.trim() === '';
  const hasAttachment = Boolean(selectedImage || selectedPdf);

  return (
    <div className="p-4 w-full bg-transparent dark:bg-transparent transition-colors duration-200">
      {(imagePreviewUrl || pdfPreviewUrl) && (
        <div className="mb-2 relative inline-block">
          {imagePreviewUrl ? (
            <img src={imagePreviewUrl} alt="Selected" className="max-h-32 rounded-md" />
          ) : (
            <div className="flex items-center space-x-2 bg-gray-100 dark:bg-gray-800 p-2 rounded-md">
              <FileIcon size={24} className="text-gray-600 dark:text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                {selectedPdf?.name || 'PDF Document'}
              </span>
            </div>
          )}
          <button
            onClick={handleRemoveFile}
            className="absolute top-1 right-1 bg-gray-800 bg-opacity-50 text-white rounded-full p-1 hover:bg-opacity-75 transition-colors duration-200"
          >
            <X size={16} />
          </button>
        </div>
      )}
      <div className="flex items-center relative">
        <textarea
          ref={textareaRef}
          rows="1"
          className="bg-[rgb(209,209,205)] dark:bg-[rgb(36,36,36)] w-full pl-16 pr-12 py-3.5 h-[56px] rounded-[28px] focus:outline-none focus:ring-2 focus:ring-[rgb(199,199,195)] dark:focus:ring-[rgb(70,70,70)] text-gray-800 dark:text-white placeholder-gray-600 dark:placeholder-gray-500 resize-none overflow-y-auto"
          placeholder={`Message ${selectedModelName}${isImageGeneration(getChatModelByModelString(selectedModel)) ? ' (Image prompt)' : ''}`}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            minHeight: '44px',
            maxHeight: '300px',
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
          }}
        />
        <div className="absolute left-2.5 bottom-2" ref={fileMenuRef}>
          <button
            onClick={() => setShowFileMenu(!showFileMenu)}
            className="p-2.5 rounded-full transition-colors duration-200 bg-gray-200 dark:bg-[rgb(22,22,22)] text-gray-600 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-500"
          >
            <Plus size={16} />
          </button>
          {showFileMenu && (
            <div className="absolute bottom-full left-0 mb-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
              <div className="py-1">
                <button
                  onClick={() => triggerFileSelect('image')}
                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <ImageIcon size={16} className="mr-2" />
                  Image
                </button>
                {isSonnet && (
                  <button
                    onClick={() => triggerFileSelect('pdf')}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <FileIcon size={16} className="mr-2" />
                    PDF
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => handleFileSelect(e, 'image')}
          accept="image/*"
          className="hidden"
        />
        <input
          type="file"
          ref={pdfInputRef}
          onChange={(e) => handleFileSelect(e, 'pdf')}
          accept="application/pdf"
          className="hidden"
        />
        <button
          onClick={handleSendMessage}
          disabled={isInputEmpty && !hasAttachment}
          className={`absolute right-2.5 bottom-2 p-2.5 rounded-full transition-colors duration-200 ${
            isInputEmpty && !hasAttachment
              ? 'bg-gray-200 dark:bg-[rgb(76,76,76)] text-gray-400 dark:text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
          }`}
        >
          <Send size={16} />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
