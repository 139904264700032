// import React, { useRef, useEffect, useState } from 'react';
// import ReactMarkdown from 'react-markdown';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { FileText, ExternalLink } from 'lucide-react';

// const CODE_BLOCK_MAX_HEIGHT = 400;
// const MAX_IMAGE_SIZE = 300;

// const ChatWindow = ({ selectedChat, messages }) => {
//   const messagesEndRef = useRef(null);

//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

//   const isImageUrl = (url) => {
//     if (/\.(jpeg|jpg|gif|png|webp)$/i.test(url)) {
//       return true;
//     }
    
//     if (url.includes('blob.core.windows.net') && url.includes('img-')) {
//       return true;
//     }
    
//     return false;
//   };

//   const CodeBlock = ({ language, value }) => {
//     const [isCopied, setIsCopied] = useState(false);
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [canToggle, setCanToggle] = useState(false);
//     const codeRef = useRef(null);

//     const handleCopy = () => {
//       navigator.clipboard.writeText(value).then(() => {
//         setIsCopied(true);
//         setTimeout(() => setIsCopied(false), 2000);
//       });
//     };

//     const handleToggle = () => {
//       setIsExpanded(!isExpanded);
//     };

//     useEffect(() => {
//       if (codeRef.current) {
//         const { scrollHeight, clientHeight } = codeRef.current;
//         setCanToggle(scrollHeight > clientHeight);
//         codeRef.current.style.maxHeight = isExpanded ? 'none' : `${CODE_BLOCK_MAX_HEIGHT}px`;
//       }
//     }, [isExpanded, value]);

//     return (
//       <div className="relative code-block" style={{ '--max-height': `${CODE_BLOCK_MAX_HEIGHT}px` }}>
//         <div className="absolute top-2 right-2 space-x-2 z-10">
//           <button 
//             onClick={handleCopy}
//             className="px-2 py-1 text-xs bg-gray-200 dark:bg-gray-600 rounded"
//           >
//             {isCopied ? 'Copied!' : 'Copy'}
//           </button>
//           {canToggle && (
//             <button 
//               onClick={handleToggle}
//               className="px-2 py-1 text-xs bg-gray-200 dark:bg-gray-600 rounded"
//             >
//               {isExpanded ? 'Collapse' : 'Expand'}
//             </button>
//           )}
//         </div>
//         <div ref={codeRef} className={`code-container ${isExpanded ? 'expanded' : ''}`}>
//           <SyntaxHighlighter
//             style={vscDarkPlus}
//             language={language}
//             PreTag="div"
//             customStyle={{
//               margin: 0,
//               padding: '1em',
//               backgroundColor: '#1E1E1E',
//             }}
//           >
//             {value}
//           </SyntaxHighlighter>
//         </div>
//         {!isExpanded && canToggle && <div className="fade-overlay" />}
//       </div>
//     );
//   };

//   const TypingIndicator = () => (
//     <div className="flex space-x-2 p-3 bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] rounded-lg max-w-[100px]">
//       <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce"></div>
//       <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce" style={{ animationDelay: '0.1s' }}></div>
//       <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
//     </div>
//   );

//   const renderMessageContent = (message) => {
//     if (message.role === 'assistant' && isImageUrl(message.text)) {
//       return (
//         <img
//           src={message.text}
//           alt="AI generated"
//           className="max-w-full h-auto rounded-lg"
//           style={{
//             maxWidth: `${MAX_IMAGE_SIZE}px`,
//             maxHeight: `${MAX_IMAGE_SIZE}px`,
//             objectFit: 'contain'
//           }}
//         />
//       );
//     }
//     return (
//       <ReactMarkdown
//         className="markdown-content"
//         components={{
//           p: ({ node, ...props }) => <p className="mb-2 last:mb-0" {...props} />,
//           a: ({ node, ...props }) => <a className="text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer" {...props} />,
//           code({ node, inline, className, children, ...props }) {
//             const match = /language-(\w+)/.exec(className || '');
//             return !inline && match ? (
//               <CodeBlock
//                 language={match[1]}
//                 value={String(children).replace(/\n$/, '')}
//               />
//             ) : (
//               <code className={`${inline ? 'bg-gray-200 dark:bg-gray-600 rounded px-1' : 'block bg-gray-200 dark:bg-gray-600 p-2 rounded'}`} {...props}>
//                 {children}
//               </code>
//             );
//           },
//           img: ({ node, ...props }) => (
//             <img 
//               {...props} 
//               className="max-w-full h-auto rounded-lg"
//               style={{
//                 maxWidth: `${MAX_IMAGE_SIZE}px`,
//                 maxHeight: `${MAX_IMAGE_SIZE}px`,
//                 objectFit: 'contain'
//               }}
//             />
//           ),
//         }}
//       >
//         {message.text}
//       </ReactMarkdown>
//     );
//   };

//   const renderAttachments = (message) => {
//     // Return early if this is not a user message
//     if (message.role !== 'user') return null;

//     // Render image if present
//     if (message.imageUrl) {
//       return (
//         <div className="relative max-w-full overflow-hidden mb-2">
//           <img 
//             src={message.imageUrl} 
//             alt="User uploaded" 
//             className="max-w-full h-auto rounded-lg"
//             style={{
//               maxWidth: `${MAX_IMAGE_SIZE}px`,
//               maxHeight: `${MAX_IMAGE_SIZE}px`,
//               objectFit: 'contain'
//             }}
//           />
//           {message.localImage && (
//             <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm rounded-lg">
//               Uploading...
//             </div>
//           )}
//         </div>
//       );
//     }

//     // Render PDF if present and no image is present
//     if (message.pdfUrl) {
//       return (
//         <div className="flex items-center gap-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg mb-2 max-w-md">
//           <FileText className="text-gray-600 dark:text-gray-400" size={24} />
//           <div className="flex flex-col flex-grow">
//             <span className="text-sm text-gray-700 dark:text-gray-300">
//               {message.pdfName || 'PDF Document'}
//             </span>
//             <a 
//               href={message.pdfUrl}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 text-sm flex items-center gap-1"
//             >
//               Open PDF <ExternalLink size={14} />
//             </a>
//           </div>
//         </div>
//       );
//     }

//     return null;
//   };

//   return (
//     <div className="flex flex-col h-full">
//       {messages.length === 0 ? (
//         <div className="h-full flex items-center justify-center">
//           <p className="text-xl text-gray-400 dark:text-gray-500 font-medium">New Chat</p>
//         </div>
//       ) : (
//         <div className="flex-grow overflow-y-auto p-4">
//           <div className="space-y-4">
//             {messages.map((message) => (
//               <div
//                 key={message.id}
//                 className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
//               >
//                 <div
//                   className={`inline-block p-3 rounded-lg ${
//                     message.role === 'user'
//                       ? 'bg-blue-500 text-white max-w-[75%]'
//                       : 'bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] text-black dark:text-white max-w-[85%]'
//                   } break-words ${message.pending ? 'opacity-50' : ''}`}
//                 >
//                   {renderAttachments(message)}
//                   {renderMessageContent(message)}
//                   {message.pending && <span className="text-xs italic"> (Sending...)</span>}
//                   {message.error && <span className="text-xs text-red-500"> (Error sending message)</span>}
//                 </div>
//               </div>
//             ))}
//             {(messages[messages.length - 1]?.role === 'user' || 
//               (messages[messages.length - 1]?.role === 'assistant' && messages[messages.length - 1]?.text === '')) && (
//               <div className="flex justify-start">
//                 <TypingIndicator />
//               </div>
//             )}
//           </div>
//           <div ref={messagesEndRef} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChatWindow;

import React, { useRef, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FileText, ExternalLink } from 'lucide-react';

const CODE_BLOCK_MAX_HEIGHT = 400;
const MAX_IMAGE_SIZE = 300;

const ChatWindow = ({ selectedChat, messages }) => {
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const lastScrollPositionRef = useRef(0);
  const scrollTimeoutRef = useRef(null);

  // Check if scroll position is at bottom
  const isAtBottom = () => {
    if (!chatContainerRef.current) return true;
    
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const scrollThreshold = 50; // Reduced threshold
    return scrollHeight - (scrollTop + clientHeight) <= scrollThreshold;
  };

  // Handle scroll events with debounce
  const handleScroll = () => {
    if (!chatContainerRef.current || scrollTimeoutRef.current) return;

    scrollTimeoutRef.current = setTimeout(() => {
      const currentScrollTop = chatContainerRef.current.scrollTop;
      const scrollingUp = currentScrollTop < lastScrollPositionRef.current;
      
      // Only set userHasScrolled if explicitly scrolling up
      if (scrollingUp) {
        setUserHasScrolled(true);
      }
      
      // If manually scrolled to bottom, reset scroll state
      if (isAtBottom()) {
        setUserHasScrolled(false);
      }
      
      lastScrollPositionRef.current = currentScrollTop;
      scrollTimeoutRef.current = null;
    }, 150); // Debounce scroll events
  };

  // Scroll to bottom with smooth behavior
  const scrollToBottom = () => {
    if (!messagesEndRef.current || !chatContainerRef.current) return;
    
    // Only auto-scroll if user hasn't scrolled up
    if (!userHasScrolled) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, []);

  // Handle messages updates
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Reset scroll state when changing chats
  useEffect(() => {
    setUserHasScrolled(false);
    lastScrollPositionRef.current = 0;
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
  }, [selectedChat]);

  const isImageUrl = (url) => {
    if (/\.(jpeg|jpg|gif|png|webp)$/i.test(url)) {
      return true;
    }
    
    if (url.includes('blob.core.windows.net') && url.includes('img-')) {
      return true;
    }
    
    return false;
  };

  const CodeBlock = ({ language, value }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [canToggle, setCanToggle] = useState(false);
    const codeRef = useRef(null);

    const handleCopy = () => {
      navigator.clipboard.writeText(value).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    };

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
      // Reset scroll position after expanding/collapsing
      setTimeout(() => {
        if (!userHasScrolled) {
          scrollToBottom();
        }
      }, 100);
    };

    useEffect(() => {
      if (codeRef.current) {
        const { scrollHeight, clientHeight } = codeRef.current;
        setCanToggle(scrollHeight > clientHeight);
        codeRef.current.style.maxHeight = isExpanded ? 'none' : `${CODE_BLOCK_MAX_HEIGHT}px`;
      }
    }, [isExpanded, value]);

    return (
      <div className="relative code-block" style={{ '--max-height': `${CODE_BLOCK_MAX_HEIGHT}px` }}>
        <div className="absolute top-2 right-2 space-x-2 z-10">
          <button 
            onClick={handleCopy}
            className="px-2 py-1 text-xs bg-gray-200 dark:bg-gray-600 rounded"
          >
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
          {canToggle && (
            <button 
              onClick={handleToggle}
              className="px-2 py-1 text-xs bg-gray-200 dark:bg-gray-600 rounded"
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </button>
          )}
        </div>
        <div ref={codeRef} className={`code-container ${isExpanded ? 'expanded' : ''}`}>
          <SyntaxHighlighter
            style={vscDarkPlus}
            language={language}
            PreTag="div"
            customStyle={{
              margin: 0,
              padding: '1em',
              backgroundColor: '#1E1E1E',
            }}
          >
            {value}
          </SyntaxHighlighter>
        </div>
        {!isExpanded && canToggle && <div className="fade-overlay" />}
      </div>
    );
  };

  const TypingIndicator = () => (
    <div className="flex space-x-2 p-3 bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] rounded-lg max-w-[100px]">
      <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce"></div>
      <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce" style={{ animationDelay: '0.1s' }}></div>
      <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
    </div>
  );

  const renderMessageContent = (message) => {
    if (message.role === 'assistant' && isImageUrl(message.text)) {
      return (
        <img
          src={message.text}
          alt="AI generated"
          className="max-w-full h-auto rounded-lg"
          style={{
            maxWidth: `${MAX_IMAGE_SIZE}px`,
            maxHeight: `${MAX_IMAGE_SIZE}px`,
            objectFit: 'contain'
          }}
        />
      );
    }
    return (
      <ReactMarkdown
        className="markdown-content"
        components={{
          p: ({ node, ...props }) => <p className="mb-2 last:mb-0" {...props} />,
          a: ({ node, ...props }) => <a className="text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer" {...props} />,
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <CodeBlock
                language={match[1]}
                value={String(children).replace(/\n$/, '')}
              />
            ) : (
              <code className={`${inline ? 'bg-gray-200 dark:bg-gray-600 rounded px-1' : 'block bg-gray-200 dark:bg-gray-600 p-2 rounded'}`} {...props}>
                {children}
              </code>
            );
          },
          img: ({ node, ...props }) => (
            <img 
              {...props} 
              className="max-w-full h-auto rounded-lg"
              style={{
                maxWidth: `${MAX_IMAGE_SIZE}px`,
                maxHeight: `${MAX_IMAGE_SIZE}px`,
                objectFit: 'contain'
              }}
            />
          ),
        }}
      >
        {message.text}
      </ReactMarkdown>
    );
  };

  const renderAttachments = (message) => {
    // Return early if this is not a user message
    if (message.role !== 'user') return null;

    // Render image if present
    if (message.imageUrl) {
      return (
        <div className="relative max-w-full overflow-hidden mb-2">
          <img 
            src={message.imageUrl} 
            alt="User uploaded" 
            className="max-w-full h-auto rounded-lg"
            style={{
              maxWidth: `${MAX_IMAGE_SIZE}px`,
              maxHeight: `${MAX_IMAGE_SIZE}px`,
              objectFit: 'contain'
            }}
          />
          {message.localImage && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm rounded-lg">
              Uploading...
            </div>
          )}
        </div>
      );
    }

    // Render PDF if present and no image is present
    if (message.pdfUrl) {
      return (
        <div className="flex items-center gap-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg mb-2 max-w-md">
          <FileText className="text-gray-600 dark:text-gray-400" size={24} />
          <div className="flex flex-col flex-grow">
            <span className="text-sm text-gray-700 dark:text-gray-300">
              {message.pdfName || 'PDF Document'}
            </span>
            <a 
              href={message.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 text-sm flex items-center gap-1"
            >
              Open PDF <ExternalLink size={14} />
            </a>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col h-full">
      {messages.length === 0 ? (
        <div className="h-full flex items-center justify-center">
          <p className="text-xl text-gray-400 dark:text-gray-500 font-medium">New Chat</p>
        </div>
      ) : (
        <div 
          ref={chatContainerRef}
          className="flex-grow overflow-y-auto p-4 scroll-smooth"
          style={{ scrollBehavior: 'smooth' }}
        >
          <div className="space-y-4">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`inline-block p-3 rounded-lg ${
                    message.role === 'user'
                      ? 'bg-blue-500 text-white max-w-[75%]'
                      : 'bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] text-black dark:text-white max-w-[85%]'
                  } break-words ${message.pending ? 'opacity-50' : ''}`}
                >
                  {renderAttachments(message)}
                  {renderMessageContent(message)}
                  {message.pending && <span className="text-xs italic"> (Sending...)</span>}
                  {message.error && <span className="text-xs text-red-500"> (Error sending message)</span>}
                </div>
              </div>
            ))}
            {(messages[messages.length - 1]?.role === 'user' || 
              (messages[messages.length - 1]?.role === 'assistant' && messages[messages.length - 1]?.text === '')) && (
              <div className="flex justify-start">
                <TypingIndicator />
              </div>
            )}
          </div>
          <div ref={messagesEndRef} />
        </div>
      )}
    </div>
  );
};

export default ChatWindow;